<template>
	<v-sheet class="asset" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<v-layout class="justify-content-between align-items-center">
						<v-flex>
							<p class="my-auto font-level-3-bold">
								<span class="fw-500">
									<b class="text-truncate d-inline-block" style="max-width: 42%; vertical-align: middle">
										<ShowValue :object="asset" object-key="name" label="asset name"></ShowValue>
									</b>
									<v-chip color="cyan" class="ml-2 mr-2" label text-color="white"
										>{{ asset.barcode }}
									</v-chip>
									<template v-if="asset.activated == 1">
										<v-chip color="#4CAF50" text-color="white" label> ACTIVE </v-chip>
									</template>
									<template v-if="asset.activated == 0">
										<v-chip color="#F44336" text-color="white" label> INACTIVE </v-chip>
									</template>
								</span>
							</p>
						</v-flex>
						<v-flex :class="`text-right ${deviceWidth > 1359.99 ? 'md6' : 'md4'}`">
							<v-btn
								v-if="getPermission('service::create')"
								v-on:click="serviceScheduleDialog = true"
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
							>
								<v-icon small left>mdi-hammer-wrench</v-icon>Service
							</v-btn>
							<v-btn
								v-if="getPermission('asset::edit')"
								v-on:click="doAction('edit')"
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
							>
								<v-icon small left>mdi-pencil</v-icon> Edit
							</v-btn>
							<v-btn
								v-if="getPermission('asset::create')"
								v-on:click="doAction('duplicate')"
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
							>
								<v-icon small left>mdi-content-duplicate</v-icon> Clone
							</v-btn>
							<v-btn
								v-if="getPermission('asset::delete')"
								v-on:click="doAction('delete')"
								color="red lighten-1 text-white"
								class="ml-2"
								depressed
								tile
							>
								<v-icon small left>mdi-delete</v-icon> Delete
							</v-btn>
						</v-flex>
					</v-layout>
					<div class="d-flex mt-4">
						<table width="100%">
							<tr>
								<td rowspan="3" width="12%" align="center">
									<ImageTemplate width="100" circle :src="asset.image"></ImageTemplate>
								</td>
								<td width="10%" class="fw-600">Asset ID</td>
								<td width="18%" class="fw-500">
									<ShowValue :object="asset" object-key="id_number" label="asset id"></ShowValue>
								</td>
								<td width="10%" class="fw-600">Asset Name</td>
								<td width="20%" class="fw-500">
									<ShowValue :object="asset" object-key="name" label="asset name"></ShowValue>
								</td>
								<td width="10%" class="fw-600">System Owner</td>
								<td width="20%" class="fw-500">
									<ShowValue :object="asset" object-key="system_owner" label="system owner"></ShowValue>
								</td>
							</tr>
							<tr>
								<td class="fw-600">Department</td>
								<td class="fw-500">
									<ShowValue :object="asset" object-key="department" label="department"></ShowValue>
								</td>
								<td class="fw-600">Manufacturer</td>
								<td class="fw-500">
									<ShowValue :object="asset" object-key="manufacturer" label="manufacturer"></ShowValue>
								</td>
								<td class="fw-600">Location</td>
								<td class="fw-500">
									<ShowValue :object="asset" object-key="location" label="location"></ShowValue>
								</td>
							</tr>
							<tr>
								<td class="fw-600">Model No.</td>
								<td class="fw-500">
									<ShowValue :object="asset" object-key="model_no" label="model no."></ShowValue>
								</td>
								<td class="fw-600">Serial No.</td>
								<td class="fw-500">
									<ShowValue :object="asset" object-key="serial_no" label="serial no."></ShowValue>
								</td>
							</tr>
						</table>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="assetTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Details
					</v-tab>
					<v-tab href="#upcoming-services">
						<v-icon small left>mdi-file-document-multiple</v-icon> Upcoming Services
					</v-tab>
					<v-tab href="#services">
						<v-icon small left>mdi-file-document-multiple</v-icon> Services
					</v-tab>
					<v-tab href="#certificate-file">
						<v-icon small left>mdi-file-document-multiple</v-icon> Certificates
					</v-tab>
					<v-tab href="#previous-certificate-file">
						<v-icon small left>mdi-file-document-multiple</v-icon> Previous Certificates
					</v-tab>
					<v-tab href="#imr-file"> <v-icon small left>mdi-file-document-multiple</v-icon> IMR </v-tab>
					<v-tab href="#imr-acknowledgement-file">
						<v-icon small left>mdi-file-document-multiple</v-icon> IMR Acknowledgement
					</v-tab>
					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files </v-tab>
					<v-tab href="#comment"> <v-icon small left>mdi-comment-multiple</v-icon> Comments </v-tab>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs</v-tab>
				</v-tabs>

				<v-tabs-items v-model="assetTab">
					<v-tab-item value="detail">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Asset Details
									</v-flex>
								</v-layout>
								<table width="100%" class="detail-table scattered-table">
									<tr>
										<th width="300px">Calibration Frequency (in months)</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="calibration_frequency"
												label="Calibration Frequency"
											></ShowValue>
										</td>
										<th width="300px">Last Calibration Date</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="last_calibration_date_formatted"
												label="Last Calibration Date"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Reminder Date</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="reminder_formatted"
												label="Reminder Date"
											></ShowValue>
										</td>
										<th width="300px">Next Calibration Date</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="next_calibration_date_formatted"
												label="Next Calibration Date"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Calibration Point And Tolerance</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="calibration_point_and_tolerance"
												label="Calibration Point And Tolerance"
											></ShowValue>
										</td>
										<th width="300px">Cost Centre</th>
										<td>
											<ShowValue :object="asset" object-key="cost_centre" label="Cost Centre"></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Calibration Location</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="calibration_location"
												label="Calibration Location"
											></ShowValue>
										</td>
										<th width="300px">Previous Vendor</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="previous_vendor"
												label="Previous Vendor"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">AML Proposal</th>
										<td>
											<ShowValue :object="asset" object-key="aml_proposal" label="AML Proposal"></ShowValue>
										</td>
										<th width="300px">Contract Price/Quote Price (SGD)</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="price_formatted"
												label="Contract Price/Quote Price"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">BPO/PO Number</th>
										<td>
											<ShowValue :object="asset" object-key="bpo_po_number" label="BPO/PO Number"></ShowValue>
										</td>
										<th width="300px">Frequency Per Year</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="frequency_per_year"
												label="Frequency Per Year"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Price Per Annum (SGD)</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="price_per_annum_sgd_formatted"
												label="Price Per Annum"
											></ShowValue>
										</td>
										<th width="300px">Service Schedule</th>
										<td width="30%">
											<ShowValue
												:object="asset"
												object-key="schedule_message"
												label="service schedule"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Process Range</th>
										<td colspan="3">
											<ShowValue :object="asset" object-key="process_range" label="Process Range"></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Comment</th>
										<td colspan="3">
											<ShowValue :object="asset" object-key="comment" label="Comment"></ShowValue>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="upcoming-services">
						<div>
							<v-layout class="p-4 border-bottom-light-grey min-height-57px">
								<v-flex class="font-level-3-bold my-auto">
									<span class="detail-svg-icon mr-2">
										<!--begin::Svg Icon-->
										<inline-svg :src="$assetURL('media/custom-svg/services-color.svg')" />
										<!--end::Svg Icon-->
									</span>
									Upcoming Services
								</v-flex>
							</v-layout>
							<div class="overflow-y" style="max-height: calc(100vh - 306px)">
								<table width="100%" class="detail-table table-head-sticky">
									<thead>
										<tr>
											<th width="5%" class="p-2 light-dark-blue-bg"></th>
											<th class="p-2 light-dark-blue-bg">Date</th>
											<th class="p-2 light-dark-blue-bg">Start Time</th>
											<th class="p-2 light-dark-blue-bg">End Time</th>
											<th class="p-2 light-dark-blue-bg">Status</th>
											<th width="15%" class="p-2 light-dark-blue-bg"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(row, index) in serviceSchedule" :key="index">
											<td width="5%" class="p-2 py-3 cursor-pointer" align="center">{{ index + 1 }}</td>
											<td class="p-2 py-3 cursor-pointer">{{ row.date_formatted }}</td>
											<td class="p-2 py-3 cursor-pointer">{{ row.start_time_formatted }}</td>
											<td class="p-2 py-3 cursor-pointer">{{ row.end_time_formatted }}</td>
											<td class="p-2 py-3 cursor-pointer">
												<v-chip
													outlined
													:color="row.schedule_status == 1 ? 'green lighten-1' : 'red lighten-1'"
												>
													{{ row.status_text }}
												</v-chip>
											</td>
											<td width="15%" class="p-2 cursor-pointer">
												<template v-if="row.schedule_status == 0">
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																v-bind="attrs"
																v-on="on"
																v-on:click="initService(row)"
																color="blue darken-4 white--text"
																depressed
																tile
															>
																<v-icon class="mr-2">mdi-hammer-wrench</v-icon> Schedule
															</v-btn>
														</template>
														<span> Schedule Service </span>
													</v-tooltip>
												</template>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="services">
						<AssetServiceListing title="Services" :asset-uuid="asset.uuid"></AssetServiceListing>
					</v-tab-item>
					<v-tab-item value="certificate-file">
						<Certificates
							v-if="assetTab == 'certificate-file'"
							class="mx-4"
							type-text="Certificates"
							type="asset"
							c-type="1"
							:type-uuid="uuid"
						></Certificates>
					</v-tab-item>
					<v-tab-item value="previous-certificate-file">
						<Previous-Certificates
							v-if="assetTab == 'previous-certificate-file'"
							class="mx-4"
							type-text="Previous Certificate"
							type="asset"
							c-type="2"
							:type-uuid="uuid"
						></Previous-Certificates>
					</v-tab-item>
					<v-tab-item value="imr-file">
						<IMR
							v-if="assetTab == 'imr-file'"
							class="mx-4"
							type-text="IMR"
							type="asset"
							c-type="3"
							:type-uuid="uuid"
						></IMR>
					</v-tab-item>
					<v-tab-item value="imr-acknowledgement-file">
						<IMR-Acknowledgement
							v-if="assetTab == 'imr-acknowledgement-file'"
							class="mx-4"
							type-text="IMR Acknowledgement"
							type="asset"
							c-type="4"
							:type-uuid="uuid"
						></IMR-Acknowledgement>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="assetTab == 'file'"
							class="mx-4"
							type-text="Asset Files"
							type="asset"
							:type-uuid="uuid"
						></Files>
					</v-tab-item>
					<v-tab-item value="comment">
						<Comments
							v-if="assetTab == 'comment'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
						></Comments>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="assetTab == 'event'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Asset"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					!asset.retired ? 'To preserve associated records, you could retire the Asset instead.' : null
				"
			>
				<template v-if="!asset.retired" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							retireDialog = true;
						"
					>
						Retire
					</v-btn>
				</template>
			</DeleteTemplate>
			<template v-if="serviceScheduleDialog">
				<ServiceScheduleTemplate
					type="Asset"
					endpoint="asset"
					:type-uuid="uuid"
					:schedule="schedule"
					v-on:success="
						getAsset();
						serviceScheduleDialog = false;
					"
					v-on:close="serviceScheduleDialog = false"
					:service-dialog="serviceScheduleDialog"
				></ServiceScheduleTemplate>
			</template>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ServiceScheduleTemplate from "@/view/components/ServiceScheduleTemplate";
import AssetServiceListing from "@/view/components/Asset-Service-Listing";
// import TooltipQuestion from "@/view/components/TooltipQuestion";
import ImageTemplate from "@/view/components/Image";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import EventListing from "@/view/components/EventListing";
import { GetAsset } from "@/core/lib/asset.lib";
import { EventBus } from "@/core/event-bus/event.bus";

export default {
	name: "asset-detail",
	title: "Detail Asset",
	data() {
		return {
			extendCheckoutDialog: false,
			checkinDialog: false,
			serviceDialog: false,
			reservationDialog: false,
			serviceScheduleDialog: false,
			serviceCompleteDialog: false,
			serviceExtendDialog: false,
			checkoutDialog: false,
			retireDialog: false,
			deleteDialog: false,
			deleteURL: null,
			deleteText: null,
			assetTab: null,
			uuid: null,
			Barcode: null,
			contentLoaded: false,
			pageLoading: true,
			asset: {},
			schedule: {},
			serviceSchedule: [],
			deviceWidth: document.documentElement.clientWidth,
		};
	},
	created() {
		window.addEventListener("resize", this.resizeEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeEventHandler);
	},
	methods: {
		initService(param) {
			this.schedule = param;
			this.serviceScheduleDialog = true;
		},
		resizeEventHandler() {
			this.deviceWidth = document.documentElement.clientWidth;
		},
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "asset-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "duplicate":
					this.$router.push({
						name: "asset-create",
						query: { duplicate: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "retire":
					this.retireDialog = true;
					break;
				case "print-label":
					break;
				case "checkout-history":
					break;
				case "verification-history":
					break;
				case "checkout":
					this.checkoutDialog = true;
					break;
				case "reserve":
					this.reservationDialog = true;
					break;
				case "extend-checkout":
					this.extendCheckoutDialog = true;
					break;
				case "checkin":
					this.checkinDialog = true;
					break;
				case "start-service":
					this.serviceDialog = true;
					break;
				case "schedule-service":
					this.serviceScheduleDialog = true;
					break;
				case "complete-service":
					this.serviceCompleteDialog = true;
					break;
				case "extend-service":
					this.serviceExtendDialog = true;
					break;
			}
		},
		getServices() {
			EventBus.$emit("reload:asset-service", true);
		},
		getAsset() {
			GetAsset(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.serviceSchedule = data.service_schedule;
					this.asset = data;
					this.deleteText = `#${data.barcode} - ${data.name}`;
					this.deleteURL = `asset/${data.uuid}`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Asset", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	components: {
		Files,
		AssetServiceListing,
		ServiceScheduleTemplate,
		Certificates: Files,
		"Previous-Certificates": Files,
		IMR: Files,
		"IMR-Acknowledgement": Files,
		ShowValue,
		Comments,
		DeleteTemplate,
		EventListing,
		// TooltipQuestion,
		ImageTemplate,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Asset", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getAsset();

		EventBus.$on("reload:asset", () => {
			this.getAsset();
		});
	},
	beforeDestroy() {
		EventBus.$off("reload:asset");
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
